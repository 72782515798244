#firebaseui-auth-container input:focus {
    outline: 0;
    box-shadow: none !important;
}

#firebaseui-auth-container {
    /* min-width: 500px; */
    
}

.firebaseui-container {
    background-color: transparent;
    box-shadow: none;
}

